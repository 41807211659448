
import { User } from '@/store/user/types';
import { Activity, ActivityType, ProgramFormula, ProgramType, StateType } from '@/types';
import { Component, Vue } from 'vue-property-decorator';
import { programTypes } from '@/variables'
import { ROOT_ACTIONS } from '@/store/actions';
import { Timestamp, addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '@/firebase';
import { USER_GETTERS } from '@/store/user/getters';

@Component({
  components: {
    FormulaTypeSpan: () => import('@/components/typography/ProgramFormulaSpan.vue')
  }
})
export default class NewVoluntaryProgram extends Vue {
  coaches: User[] = [];
  participants: User[] = [];

  inputs = {
    title: '',
    formula: ProgramFormula.ALGEMEEN_OPZEG,
    type: ProgramType.GROUP,
    coach: {
      fullName: '',
    } as User,
    participant: {
      fullName: '',
    } as User,
    start: '',
    hours: 0
  }

  loading = {
    submit: false
  }

  getTimeStampFromString(date: string): Timestamp {
    const dateArray = date.split('/');

    return Timestamp.fromDate(new Date(parseInt(dateArray[2], 10), parseInt(dateArray[1], 10) - 1, parseInt(dateArray[0], 10)));
  }

  get programFormulas() {
    return Object.keys(ProgramFormula)
      .filter((v) => isNaN(Number(v)))
      .map((name) => {
        return {
          id: ProgramFormula[name as keyof typeof ProgramFormula],
          name,
        };
      });
  }

  rules = {
    date: [
      (v: string) =>
        /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) ||
        "Ongeldig formaat"
    ]
  }

  _programTypes = programTypes;

  mounted() {
    this.fetchCoachesAndParticipants();
  }

  async fetchCoachesAndParticipants() {
    const availabeCoaches = await getDocs(query(collection(firestore, 'users'), where('type', 'in', ['COACH', 'ADMIN'])));

    this.coaches = availabeCoaches.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data(),
        fullName: `${doc.data().firstname} ${doc.data().lastname}`,
      } as User
    });

    const participants = await getDocs(query(collection(firestore, 'users'), where('type', '==', 'PARTICIPANT')))

    this.participants = participants.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data(),
        fullName: `${doc.data().firstname} ${doc.data().lastname}`,
      } as User
    });
  }

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  async submit() {
    try {
      this.loading.submit = true;



      const addedProgram = await addDoc(collection(firestore, 'programs'), {
        coach: this.inputs.coach.id,
        participant: this.inputs.participant.id,
        formula: this.inputs.formula,
        type: this.inputs.type,
        title: this.inputs.title,
        isArchived: false,
      });

      const addedPhase = await addDoc(collection(firestore, 'phases'), {
        title: this.inputs.participant.language === 'nl-be' ? 'Algemeen' : 'Général',
        index: 0,
        programId: addedProgram.id,
        start: this.getTimeStampFromString(this.inputs.start),
        end: this.getTimeStampFromString(this.inputs.start),
      });

      await addDoc(collection(firestore, 'activities'), {
        name: this.inputs.participant.language === 'nl-be' ? 'Algemeen' : 'Général',
        confirmations: [],
        description: '',
        plannedHours: this.inputs.hours,
        isOnline: false,
        end: Timestamp.fromDate(new Date()),
        start: Timestamp.fromDate(new Date()),
        required: false,
        phaseId: addedPhase.id,
        type: ActivityType.INDIVIDUAL_COACHING,
      })

      await addDoc(collection(firestore, 'program-states',), {
        date: new Date(),
        comment: "",
        programId: addedProgram.id,
        type: StateType.STARTED,
        user: this.user.id
      });

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Programma aangemaakt'
      })
      this.$router.push('overview');

    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading.submit = false;
    }
  }
} 
